import { ReactNode } from "react";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { firstValueFrom } from "src/utils/observable-to-promise";

export interface IConfirmationState {
	visible: boolean;
	header?: string;
	message?: ReactNode;
	confirmText?: string;
	cancelText?: string;
	hideClose?: boolean;
	accept?: () => Promise<void> | void;
	reject?: () => Promise<void> | void;
	accepted?: boolean;
	hideButtons?: boolean;
	hideCancel?: boolean;
	scrollable?: boolean;
	disableEnforceFocus?: boolean;
}

class ConfirmationModalService {
	private _defaultState: IConfirmationState = {
		accept: () => {
			console.warn("no action specified");
		},
		reject: () => undefined,
		visible: false,
		accepted: undefined,
		hideCancel: false,
		hideButtons: false,
		hideClose: false,
		confirmText: undefined,
		cancelText: undefined,
		header: undefined,
		message: undefined,
		scrollable: false,
		disableEnforceFocus: false,
	};
	private _state$ = new BehaviorSubject<IConfirmationState>(this._defaultState);

	public getState(): Observable<IConfirmationState> {
		return this._state$.asObservable();
	}

	public confirm(stateUpdate: Partial<IConfirmationState>): void {
		this._updateState({
			...this._defaultState,
			...stateUpdate,
			visible: true,
		});
	}

	public confirmAsPromise(
		stateUpdate: Partial<IConfirmationState>,
	): Promise<boolean> {
		this.confirm(stateUpdate);
		return firstValueFrom(
			this._state$.pipe(
				filter((state) => !state.visible),
				map((state) => !!state.accepted),
			),
		);
	}

	public async accept(): Promise<void> {
		const { accept } = this._state$.value;
		this._close(true);
		await accept?.();
	}

	public async reject(): Promise<void> {
		const { reject } = this._state$.value;
		this._close(false);
		await reject?.();
	}

	private _close(accepted: boolean): void {
		this._updateState({ ...this._defaultState, visible: false, accepted });
	}

	private _updateState(stateUpdate: Partial<IConfirmationState>): void {
		this._state$.next({ ...this._state$.value, ...stateUpdate });
	}
}

export const confirmationModalService = new ConfirmationModalService();
