import { EAccountType, ESubscriptionStatus, getIsAccountActive } from "amp";
import { fetchDataDynamic } from "src/hooks/useFetchDataDynamic";
import { FleetProvider } from "../fleet-accounts/contexts/FleetContext";
import { useContext, useEffect, useState } from "react";
import { UserAndAccountContext } from "src/contexts/UserAndAccountContext";
import LoadingScreen from "src/components/LoadingScreen";

export const FleetAdminPortal = ({ children }) => {
	const [accountId, setAccountId] = useState(undefined);
	const { 
		setAccountId: setAccountIdHeader, 
		userId, 
		accountId: accountIdHeader 
	} = useContext(UserAndAccountContext);

	async function getAccountId(): Promise<void> {
		const response = await fetchDataDynamic("account", {
			columns: ["external_id__c", "primary_account_holder__r__external_id__c", "stripe_subscription_status__c"],
			condition: {
				conditions: [{
					conditions: [{
						left: { column: "account_type__c" },
						operator: "EQ",
						right: EAccountType.Fleet,
					},
					{
						left: { column: "account_type__c" },
						operator: "EQ",
						right: EAccountType.FleetPayPerWash,
					}],
					operator: "OR",
				}, {
					left: { column: "is_deleted" },
						operator: "EQ",
						right: false,
				}],
				operator: "AND",
			},
			joins: [{
				type: "INNER",
				relation: "mobileUserAccounts",
				condition: {
					left: { column: "mobile_user_id", alias: "root_mobileUserAccounts" },
					operator: "EQ",
					right: userId,
				}
			}],
		});

		if (response?.length) {
			let fleetAccountId = response.find(
				({
					primary_account_holder__r__external_id__c,
					stripe_subscription_status__c,
				}) =>
					primary_account_holder__r__external_id__c === userId &&
					getIsAccountActive(
						(stripe_subscription_status__c as unknown) as ESubscriptionStatus
					)
			)?.external_id__c;
			if (!fleetAccountId) {
				fleetAccountId = response[0].external_id__c;
			}
			setAccountIdHeader(fleetAccountId);
			setAccountId(fleetAccountId);
		} else {
			throw new Error("No fleet account found");
		}
	}

	useEffect(() => {
		if (userId) {
			getAccountId();
		}
	}, [userId]);

	if (!accountId || !accountIdHeader) {
		return <LoadingScreen />;
	}

	return (
		<FleetProvider fleetAccountId={accountId}>
			{children}
		</FleetProvider>
	);
};