import {
	ITopArcadeUser,
	IUserArcadeGameStats,
	IUserResponse,
	IUserUpdate,
	IBillingUser,
	IAcknowledgmentAcceptance,
	IUserSignature,
	IMobileUser,
	EUserStatusType,
	XOptional,
 EEndpointType } from 'amp';

import axios from 'src/utils/axios';
import { BILLING_ACCOUNT_ENDPOINT } from './account-service';
import { countDataDynamic, fetchDataDynamic } from 'src/hooks/useFetchDataDynamic';

export interface IDigitalAcknowledgment {
	id: string;
	createddate: Date;
	acknowledgmentName: string;
	image_url?: string;
}

export interface IAuthData {
	name?: string;
	email: string;
	hasUserId: boolean;
	hasAuthId: boolean;
	hasPassword: boolean;
	migrationId: XOptional<string>;
	userAuthStatus: EUserStatusType | undefined;
}

const _USER_ADMIN_ENDPOINT = `/${EEndpointType.SiteLead}/admin-management`;

const _DELETE_USER_ENDPOINT = `/${EEndpointType.User}/user-delete`;

const _MOBILE_APP_ACTIVITY_ENDPOINT = `/${EEndpointType.User}/mobile-app-activity`;

const USER_PORTAL_URL = "/app/management/users-and-accounts/users";
const SITE_LEAD_USER_PORTAL_URL = "/site-lead/users-and-accounts/users";

const PORTAL_URL_EDIT = USER_PORTAL_URL + "/edit/:userExtId";
const SITE_LEAD_USER_PORTAL_URL_EDIT = SITE_LEAD_USER_PORTAL_URL + "/edit/:userExtId";

const getPortalUrlEdit = (id?: number | string, isSiteLead?: boolean) => {
    const BASE_PORTAL_URL_EDIT = isSiteLead ? SITE_LEAD_USER_PORTAL_URL_EDIT : PORTAL_URL_EDIT;
    return id ? BASE_PORTAL_URL_EDIT.replace(":userExtId", id.toString()) : BASE_PORTAL_URL_EDIT;
};

const getUser = async (userExtId: string) => {
	return axios.post<IUserResponse>(`${_USER_ADMIN_ENDPOINT}/get-user`, {
		userExtId
	});
};

const getAcknowledgmentAcceptances = async (userExtId: string) => {
	return fetchDataDynamic("acknowledgmentAcceptance", {
		condition: {
			left: { column: "mobile_user__r__external_id__c" },
			operator: "EQ",
			right: userExtId,
		},
		columns: ["createddate", "external_id__c"],
		joins: [
			{
				relation: "acknowledgment",
				columns: ["name"],
			},
			{
				relation: "userSignature",
			},
		],
	});
};

const getUserSignatures = async (userExtId: string) => {
	return fetchDataDynamic("userSignatures", {
		condition: {
			left: { column: "user_id" },
			operator: "EQ",
			right: userExtId,
		},
		columns: ["image_url", "created", "id"],
		joins: [
			{
				relation: "acknowledgmentAcceptance",
			}
		]
	});
};

const getDigitalAcknowledgments = async (
	userExtId: string,
): Promise<IDigitalAcknowledgment[]> => {
	const [acceptanceData, signatureData] = await Promise.all([
		getAcknowledgmentAcceptances(userExtId),
		getUserSignatures(userExtId),
	]);
	return createDigitalAcknowledgments(acceptanceData, signatureData);
};

const createDigitalAcknowledgments = (
	acceptances: IAcknowledgmentAcceptance[],
	signatures: IUserSignature[],
): IDigitalAcknowledgment[] => {
	const digitalAcknowledgments = acceptances.map((item) => ({
		id: item.external_id__c,
		createddate: item.createddate,
		acknowledgmentName: item.acknowledgment?.name,
		image_url: item.userSignature?.image_url ?? undefined,
	}));

	// Add user signatures from Attendant Mode signup to dataset
	// This helps include signatures that were not linked
	// to an acknowledgment acceptance (in this case just attendant mode sign ups)
	signatures.forEach((item) => {
		if (!item.acknowledgmentAcceptance) {
			digitalAcknowledgments.push({
				id: String(item.id),
				createddate: item.created,
				acknowledgmentName: "Attendant Mode Signup",
				image_url: item.image_url,
			});
		}
	});
	return digitalAcknowledgments;
};

const getUserArcadeStats = async (userExtId: string) => {
	return axios.post<IUserArcadeGameStats>(`${_MOBILE_APP_ACTIVITY_ENDPOINT}/get-user-game-activity`, {
		userExtId
	});
};

const getTopArcadeUsers = async (numberOfDays?: number) => {
	return axios.post<ITopArcadeUser[]>(`${_MOBILE_APP_ACTIVITY_ENDPOINT}/get-top-arcade-users`, {
		numberOfDays,
	});
};

const updateUserInDb = async (updates: IUserUpdate) => {
	return axios.post<IUserResponse>(`${_USER_ADMIN_ENDPOINT}/update-user`, updates);
};

const getBillingUser = async (userExtId: string): Promise<IBillingUser> => {
	return (await (axios.post<IBillingUser>(`${BILLING_ACCOUNT_ENDPOINT}/user-billing-data`, { userExtId }))).data;
};

const deleteUser = async (userId: string) => {
	return axios.post<IMobileUser>(`${_DELETE_USER_ENDPOINT}/delete`, { userId });
};

const getCanDeleteUser = async (userId: string) => {
	return axios.post<boolean>(`${_DELETE_USER_ENDPOINT}/validate`, { userId });
};

const confirmAuthAccount = async (email: string): Promise<IAuthData> => {
	return (await (axios.get<IAuthData>(`/${EEndpointType.Anonymous}/user/find-by-email/${email}`))).data;
};

const getIsValidUserId = async (userExtId: string): Promise<boolean> => {
	const userCount = await countDataDynamic("users", {
		condition: {
			conditions: [
				{
					left: { column: "external_id__c" },
					operator: "EQ",
					right: userExtId,
				},
				{
					left: { column: "is_deleted" },
					operator: "EQ",
					right: false,
				},
			],
			operator: "AND",
		},
		columns: ["id"],
	});
	return userCount === 1;
};

export const UserService = {
	updateUserInDb,
	getUserArcadeStats,
	getUser,
	getTopArcadeUsers,
	getPortalUrlEdit,
	getBillingUser,
	getCanDeleteUser,
	deleteUser,
	getDigitalAcknowledgments,
	confirmAuthAccount,
	getIsValidUserId,
	USER_PORTAL_URL,
	SITE_LEAD_USER_PORTAL_URL,
};
